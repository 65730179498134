/// <reference lib="WebWorker" />
import { enableOfflineSupport } from './lib/enableOfflineSupport'
import { enablePushNotifications } from './lib/enablePushNotifications'

// export {} // Fixes '--isolatedModules' error if nothing is imported

enableOfflineSupport({
  cacheName: 'offline-v1.2',
  offlineUrl: '/static/offline.html',
})

enablePushNotifications()
