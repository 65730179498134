/// <reference lib="WebWorker" />
declare let self: ServiceWorkerGlobalScope

export const enablePushNotifications = (): void => {
  self.addEventListener('push', (event) => {
    if (!event.data) return
    const data = event.data.json()
    if (!data.orderId) return
    const title = `Order #${data.orderId} has shipped!`
    self.registration.showNotification(title, {
      body: 'View order details and updates.',
      data,
      icon: '/static/pwa/manifest-icon-512.maskable.png',
      badge: '/static/pwa/badge-icon-128.png',
    })
  })

  self.addEventListener('notificationclick', (event) => {
    event.notification.close()
    const data = event.notification.data || {}
    if (!data.orderId) return
    self.clients.openWindow(`/order-center/orders/${data.orderId}`)
  })
}
