/// <reference lib="WebWorker" />
declare let self: ServiceWorkerGlobalScope

// Offline fallback code adapted from https://web.dev/offline-fallback-page/
/* eslint-disable sonarjs/prefer-immediate-return */

interface EnableOfflineSupportOptions {
  cacheName: string
  offlineUrl: string
}

export const enableOfflineSupport = ({
  cacheName,
  offlineUrl,
}: EnableOfflineSupportOptions) => {
  self.addEventListener('install', (e) => {
    e.waitUntil(
      (async () => {
        const cache = await caches.open(cacheName)
        // Setting { cache: 'reload' } in the new request will ensure that the
        // response isn't fulfilled from the HTTP cache; i.e., it will be from
        // the network.
        await cache.add(new Request(offlineUrl, { cache: 'reload' }))
      })()
    )
    // Force the waiting service worker to become the active service worker.
    self.skipWaiting()
  })

  self.addEventListener('activate', (e) => {
    e.waitUntil(
      (async () => {
        // Enable navigation preload if it's supported.
        // See https://developers.google.com/web/updates/2017/02/navigation-preload
        if ('navigationPreload' in self.registration) {
          await self.registration.navigationPreload.enable()
        }
      })()
    )

    // Tell the active service worker to take control of the page immediately.
    self.clients.claim()
  })

  self.addEventListener('fetch', (e) => {
    // We only want to call event.respondWith() if this is a navigation request
    // for an HTML page.
    if (e.request.mode === 'navigate') {
      e.respondWith(
        (async () => {
          try {
            // First, try to use the navigation preload response if it's supported.
            const preloadResponse = await e.preloadResponse
            if (preloadResponse) {
              return preloadResponse
            }

            // Always try the network first.
            const networkResponse = await fetch(e.request)
            return networkResponse
          } catch (error) {
            // catch is only triggered if an exception is thrown, which is likely
            // due to a network error.
            // If fetch() returns a valid HTTP response with a response code in
            // the 4xx or 5xx range, the catch() will NOT be called.
            console.info(
              '[SW] Fetch failed; returning offline page instead.',
              error
            )

            const cache = await caches.open(cacheName)
            const cachedResponse = await cache.match(offlineUrl)
            return cachedResponse
          }
        })()
      )
    }
  })
}
